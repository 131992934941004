// Import "text-security" fonts
// @font-face {
//     font-family: "text-security-disc";
//     src: url("text-security/dist/text-security-disc.eot");
//     src: url("text-security/dist/text-security-disc.eot?#iefix") format("embedded-opentype"), url("text-security/dist/text-security-disc.woff2") format("woff2"),
//         url("text-security/dist/text-security-disc.woff") format("woff"), url("text-security/dist/text-security-disc.ttf") format("truetype"),
//         url("text-security/dist/text-security-disc.svg#text-security") format("svg");
// }
$theme-colors: (
    "primary": #0A529E,
    "secondary": #c3c3c3,
    "danger": #fd1300,
    "success": #0eba00,
);

@import "@fontsource/poppins/400.css";
@import "@fontsource/poppins/600.css";
@import "@fontsource/poppins/700.css";
@import "@fontsource/poppins/400-italic.css";
@import "@fontsource/poppins/600-italic.css";
@import "@fontsource/poppins/700-italic.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@media print {
    body {
        display: none;
        visibility: hidden;
    }
}

body {
    font-family: Poppins, "Helvetica Neue", sans-serif;
}

.cursor-pointer,
.clickable {
    cursor: pointer;
}

.cursor-move {
    cursor: move;
}

.fill-space {
    flex: 1 1 auto;
}

.ng-sidebar {
    background: #0A529E;
    padding: 0 10px;
    min-width: 230px;
}

.menuitem {
    color: #ddd;
}

.divider {
    margin-top: 5px;
    margin-bottom: 5px;
    height: 1px;
    background-color: #666;
}

.modal-dialog {
    display: flex;
    align-items: center;
    min-height: 90%;
}

.modal-header {
    background: #0A529E;
    color: white;
}

.modal-header .modal-title {
    text-align: center;
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: 0px;
    color: #fff;
}

.modal-header>button>span {
    // background: #0A529E;
    color: #fff;
}

.modal-content {
    animation-name: example;
    animation-duration: 0.5s;
    border-radius: 5px;
    border: 1px solid #707070;
    box-shadow: 0px 3px 6px #00000029;
}

.modal-body,
.modal-footer {
    background-color: #F7F7F7;
}

.modal-body .modal-title {
    text-align: center;
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: 0px;
    color: #0A529E;
    margin-bottom: 24px;
}

.modal-footer {
    border-top: 0px solid #dee2e6 !important;
    justify-content: center !important;
}

@keyframes example {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.shadow {
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}

.text-xs {
    font-size: 0.7rem;
}

#snackbar.error {
    background-color: red !important;
}

#snackbar.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

.modal-xl {
    max-width: 600px !important;
}

button,
a {
    outline: none;
}

.sent_msg p a {
    color: white !important;
}

.ng-autocomplete {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.autocomplete-container {
    box-shadow: none !important;
    margin-left: -12px !important;
    /*margin-top: -7px !important;*/
    width: 103% !important;
}

.input-container input {
    background-position: 0% !important;
    background-size: 0px 18px !important;
}

#__lpform_input_idx_0 {
    display: none !important;
}

.container {
    max-width: 90% !important;
}

table {
    width: 100%;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #F7F7F7 !important;
}

table th,
table td {
    border-bottom: 1px solid #cacaca;
    text-transform: capitalize;
    padding: 25px;
}

table th {
    font-size: 18px;
    line-height: 18px;
    color: #0A529E;
    font-weight: 700;
}

table td {
    font-size: 18px;
    line-height: 18px;
    color: #2d2d2d;
    font-weight: 400;
}

.progress-bar.bar {
    background: #b9b9b9;
    border-radius: 20px;
}

.progress {
    background: #ebebeb 0% 0% no-repeat padding-box;
    border-radius: 20px;
    font-weight: 600;
    width: 70%;
}

body .form-control {
    border-radius: 5px;
    border: 1px solid #C3C3C3;
    font-size: 16px;
    line-height: 16px;
    color: #2D2D2D;
    padding: 10px 18px;
    height: auto;
}

.form-group label {
    text-align: left;
    font-size: 12px;
    line-height: 25px;
    font-weight: 600;
    letter-spacing: 0;
    color: #0A529E;
    text-transform: uppercase;
    margin: 0;
}

.modal-goal-large-center .modal-lg {
    max-width: 60% !important;
}

.radio-checkbox-label {
    margin: 0px;
    display: inline !important;
    cursor: pointer;
}

.radio-checklist {
    cursor: pointer;
}

.autocomplete-overlay {
    position: absolute !important;
}

.tab {
    margin-bottom: 24px;
    display: flex;
    justify-content: center;

    .tablinks {
        background-color: white;
        padding: 13px 24.5px 11px;
        color: #2D2D2D;
        font-size: 16px;
        line-height: 16px;
        font-family: "proxima-soft", sans-serif;
        font-weight: 700;
        font-style: normal;
        display: flex;
        align-items: center;
        border: 1px solid #C3C3C3;

        &:first-child {
            border-radius: 5px 0 0 5px;
        }

        &:last-child {
            border-radius: 0 5px 5px 0;
        }

        &:hover {
            text-decoration: none;
        }
    }

    img {
        margin-right: 8px;
    }

    .active {
        background-color: #0A529E;
        color: #fff;
        border: 1px solid #0A529E;
    }

    i {
        margin-right: 8px;
    }
}

button img {
    margin-right: 8px;
}

@media only screen and (max-width: 1600px) {
    .container {
        max-width: 93% !important;
    }

    // .ng-sidebar {
    //     width: 220px;
    // }
    table tr {
        height: 45px;
    }

    table th,
    table td {
        padding: 17px;
        font-size: 15px;
    }

    .form-control {
        border-radius: 6px;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: -0.3px;
        padding: 6px 13px;
    }

    .modal-goal-large-center .modal-lg {
        max-width: 67% !important;
    }
}

.modal-large .modal-xl {
    max-width: 90% !important;
}

.modal-medium .modal-xl {
    max-width: 70% !important;
}

.modal-medium .modal-xl {
    max-width: 70% !important;
}


.modal-center .modal-lg {
    max-width: 45% !important;
    margin-top: 100px;
}

.modal-lg-small-center .modal-sm {
    max-width: 35% !important;
}

.modal-lg-xAxis-filter .modal-sm {
    max-width: 403px !important;
}


.modal-sm-billing {
    height: 500px !important;
}


.modal-lg-small .modal-sm {
    max-width: 350px !important;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked+.slider {
    background-color: #0A529E;
}

input:focus+.slider {
    box-shadow: 0 0 1px #0A529E;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.fa-fw {
    width: 25px;
}

.pills>ul {
    padding-left: 30px;
}

.blur {
    filter: blur(5px);
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
}

.bg-primary {
    color: #fff;
    background-color: #0A529E !important;
    border-color: #0A529E !important;
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #0A529E !important;
    border-color: #0A529E !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #06407E !important;
}

.alternative_cls:nth-child(odd) {
    font-weight: 600;
    // color: red;
}

// .alternative_cls:nth-child(even) {
//     font-weight: 600;
//     color: green;
// }

.custom-checkbox {
    .custom-control-label {
        color: black;
    }
}

button[aria-label="Close"]:focus {
    outline: none;
}

/* width */
::-webkit-scrollbar {
    width: 12px !important;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey !important;
    border-radius: 10px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #06407E !important;
    border-radius: 10px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #06407E !important;
}

:root {
    scrollbar-color: #06407E #fff !important;
    scrollbar-width: thin !important;
}

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

.bg-primary {
    background-color: #0A529E !important;
}

@import "@angular/cdk/overlay-prebuilt.css";



#clinical_summary-panel,
#incident-panel {
    .card-body {
        padding-top: 0px !important;
        padding-bottom: 2px !important;
    }
}

#goal-panel {
    .card-body {
        padding: 4px 0px;
    }
}

.chat {
    .card {
        border-bottom-left-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
    }

}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

.bg-dark {
    background-color: #06407E !important;
}

.heading {
    color: #0A529E;
    text-transform: uppercase;
    margin-left: 15px;
    font-size: 36px;
    line-height: 36px;
    font-weight: 700;
}

.dropdown-menu-right {
    right: 0;
    left: 0;
}

.logo-title {
    color: white;
}

.chartbenchMark {
    background-color: #24B6FF;
    color: #fff;
    font-size: 10px;
    padding: 2px;
}

.allPractices {
    background-color: #A5A5A5;
    color: #fff;
    font-size: 10px;
    padding: 2px;
}

.patient-billing {
    .col-6 {
        padding: 5px !important;
    }

    .row {
        margin: 0px !important;
    }
}

.chartPrintTitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    font-family: Poppins, "Helvetica Neue", sans-serif;
    color: #2D2D2D;
}

.dropdown-list {
    position: inherit !important;
}

.graphloader {
    height: 100px;
    display: flex;
    justify-content: center;
}

.range-accordion i {
    color: #fff;
    margin: 0 0 0 10px !important;
}

.range-accordion .card-header {
    border-radius: 4px 4px 0px 0px;
    padding: 0 !important;
}

.range-accordion .btn-link.collapsed {
    background-color: #A5A5A5 !important;
}

.range-accordion .btn-link {
    width: 100%;
    padding: 5px 14px 5px 23px;
    background-color: #0A529E !important;
}

.range-accordion .btn-link:focus {
    text-decoration: none;
}

.accordion-title {
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    color: #FFFFFF;
}

.btn-default {
    border: 1px solid #C3C3C3;
    border-radius: 5px;
    font-size: 14px;
    line-height: 14px;
    font-family: Poppins, "Helvetica Neue", sans-serif;
    font-weight: 600;
    letter-spacing: 0px;
    color: #2D2D2D;
    padding: 12px 11px !important;
    min-width: 132px;
    text-align: center;
    display: inline-block;

    img {
        margin-right: 8px;
    }
}

.btn-default:hover {
    opacity: 0.7;
    color: #2D2D2D;
}

#chat-panel-header .collapsed .btnunread,
#incident-panel-header .collapsed .new-button,
#clinical_summary-panel-header .collapsed .new-button {
    background: #C3C3C3 0% 0% no-repeat padding-box;
    border: 1px solid #C3C3C3;
}

#chat-panel-header .collapsed select {
    background: #C3C3C3 0% 0% no-repeat padding-box;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
    font-weight: bold;
    color: black;
}

.bold {
    font-weight: bold;
}

.border-radius {
    border-radius: 5px 5px 5px 5px !important;
}

.pagination .pagination {
    height: auto;
    margin: 0;
}

.pagination .pagination .page-link {
    padding: 10px 20px;
}


.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    height: 20px;
}