// bootstrap overrides

/* Clio Theme Global Overrides */
body {
    color: #2d2d2d;
    font-family: "proxima-nova", sans-serif;
    font-size: 16px;
}

.btn {
    cursor: pointer !important;
}

.btn-primary {
    background-color: #0A529E !important;
    border-color: #0A529E !important;
    color: #ffffff !important;
}

// .btn-primary:hover {
//     color: #000000 !important;
//     // filter: brightness(110%) !important;
// }
.btn-primary:disabled {
    cursor: default !important;
}

.btn-secondary {
    background-color: #c3c3c3 !important;
    border-color: #c3c3c3 !important;
    color: #ffffff !important;
}

// .btn-secondary:hover {
//     color: #000000 !important;
//     // filter: brightness(110%) !important;
// }
.btn-secondary:disabled {
    background-color: #ffffff !important;
    color: #c3c3c3 !important;
    border-color: #c3c3c3 !important;
    cursor: default !important;
    border: 1px solid grey !important;
}

// clio custom styles

.btn-menu {
    background-color: #ffffff !important;
    border-color: #c3c3c3 !important;
    color: #2d2d2d !important;
    font-size: 20px !important;
    font-weight: normal !important;
}

.btn-menu:focus {
    box-shadow: none;
}

.btn-menu:hover {
    background-color: #0A529E !important;
    color: #ffffff !important;
}

.btn-menu:disabled {
    background-color: #ffffff !important;
    color: #c3c3c3 !important;
    border-color: #c3c3c3 !important;
    cursor: default !important;
}

.category-header label {
    color: #0A529E;
    font-size: 24px !important;
    font-weight: 500 !important;
    width: 100% !important;
    text-align: center !important;
}

.category-header p {
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    width: 100%;
    color: #2d2d2d;
}

.btn-line {
    background: none;
    border-radius: 5px;
    border: 1px solid grey;
    color: grey;
    margin-right: 10px;
    height: 38px;
    font-size: 17px;
}
.btn-line:hover {
    background: #06407E;
    color: #fff !important;
}
.error {
    border: 1px solid #fd1300 !important;
}

.custom-control-label {
    padding-top: 7px;
}

.modal-body {
    max-height: calc(100vh - 50px);
    overflow-x: hidden;
}

.text-danger {
    font-size: 14px;
    color: #fd1300 !important;
}

.inp-err {
    font-size: 11px;
    color: #fd1300 !important;
}

.main-row {
    float: left;
    width: 100%;
    padding-bottom: 24px;
}

.main-left {
    float: left;
    width: 50%;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
}

.main-right {
    float: left;
    width: 50%;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
}

.main-left button {
    width: 100%;
}

.main-right button {
    width: 100%;
}

.main-row .form-group label,
.main-row .woman-section-content label,
.form-group label {
    text-transform: none;
    font-size: 14px;
    font-weight: 400;
    color: #868686 !important;
    line-height: 20px;
}

.main-row .radio-checkbox-label {
    text-transform: none;
    font-size: 14px;
    font-weight: 400;
    color: #2d2d2d;
}

.main-row .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.main-row .upload-btn-wrapper label {
    width: 100%;
}

.main-row .uploadbtn {
    background: none;
    border-radius: 6px;
    border: 1px solid #ced4da;
}

.main-row .uploadbtnDirty {
    background: none;
    border-radius: 6px;
    border: 1px solid red;
}

.main-row .image-block input[type="file"] {
    font-size: 17px;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 190px;
}

.main-row .imagePreview {
    width: 100%;
    height: 100%;
}

.main-row .photoid-image {
    max-width: 100%;
    height: 190px;
    padding-top: 4px;
    padding-bottom: 4px;
}

.main-row .image-block {
    float: left;
    width: 100%;
    margin-bottom: 17px;
}

.main-row .checklist-row {
    padding-left: 15px;
    padding-right: 15px;
}

.main-row .radio-checkbox-label {
    position: absolute;
    margin-left: 4px;
    line-height: 24px;
}

.main-row .checklist-columns {
    text-align: left;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 12px;
}

.main-row .checklist-columns .subset {
    margin-left: 15px;
}

.main-row-footer {
    float: left;
    // margin-top: 20px;
}

.main-row .others {
    width: 100%;
}

.main-row .others label {
    margin-bottom: 0px;
    font-size: 14px;
}

.main-row .text-danger {
    margin-left: 15px;
    margin-top: 5px;
}

.main-row .others-textarea {
    width: 100%;
    height: 75px;
    border-radius: 4px;
    background: #ffffff;
    color: #2d2d2d;
    font-size: 18px;
    font-family: poppins, sans-serif;
    font-weight: 400;
    font-style: normal;
    border: 1px solid #c3c3c3;
}

.left-chevron {
    color: lightgray;
    padding-left: 5px;
    padding-right: 10px;
}

.fa-chevron-left {
    font-size: 27px;
}

.filter-section-left {
    float: left;
    width: 50%;
}

.filter-section-right {
    float: right;
    width: 50%;
}

.filter-section {
    float: left;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 20px;
}

.filter-input {
    width: 180px;
    margin-left: 8px;
    padding-left: 5px;
    border-radius: 6px;
    border: 1px solid grey;
    height: 38px;
}

.search-div {
    position: relative;
}

.search-div .fa-search {
    position: absolute;
    left: 9px;
    top: 50%;
    margin-top: -9px;
    color: gray;
}

input[type="text"],
input[type="date"],
input[type="password"],
input[type="number"],
input[type="textarea"],
textarea,
.custom-select {
    height: 44px;
    border-radius: 4px;
    background: #ffffff;
    color: #2d2d2d;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    border: 1px solid #c3c3c3;
}
.main-row {
    padding-bottom: 40px;
}

.btn {
    font-family: "proxima-nova", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #A3A3A3;
    padding: 13px 20px 11px;
    border-radius: 4px;
    border: 1px solid #C3C3C3;
    i {
        margin-right: 13px;
    }
}
.btn:hover {
    opacity: 0.8;
}
.form-group {
    margin-bottom: 12px;
}

.uploadbtn {
    height: 215px;
}

.main-left {
    padding-left: 6px !important;
    padding-right: 6px !important;
}

.main-right {
    padding-left: 6px !important;
    padding-right: 6px !important;
}

.main-full {
    padding-left: 6px !important;
    padding-right: 6px !important;
}

.main-row {
    padding-bottom: 24px;
}

label {
    // color: #868686 !important;
}

.others-label {
    color: #868686;
}

.checkbox-group div {
    padding-bottom: 12px;
}

h4 {
    color: #2d2d2d;
}

.checkbox-group div input {
    vertical-align: middle;
}

.text-muted {
    color: #868686 !important;
}

.roomname {
    padding-bottom: 24px;
}

.roomname label {
    text-transform: none;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #868686 !important;
    line-height: 20px;
    text-align: left !important;
    margin-bottom: 2px !important;
}

.aclass {
    margin-left: -15px;
}

.main-full {
    padding-left: 6px;
    padding-right: 6px;
}

.modal-footer button {
    min-width: 100px;
}

/*.datatable th.desc:before {
    content: "\f063";
    font-family: "Font Awesome 5 Free";
    display: block;
    width: 22px;
    height: 22px;
    float: left;
}

.datatable th.asc:before {
    content: "\f062";
    font-family: "Font Awesome 5 Free";
    display: block;
    width: 22px;
    height: 22px;
    float: left;
}
*/
.datatable th.desc:after {
    content: "\f0d7";
    font-family: "Font Awesome 5 Free";
    margin-left: 8px;
}

.datatable th.asc:after {
    content: "\f0d8";
    font-family: "Font Awesome 5 Free";
    margin-left: 8px;
    position: relative;
    top: 2px;
}

.pagination {
    height: 37px;
}
.pagination .page-item.disabled .page-link {
    color: #C3C3C3;
    border-color: #C3C3C3;
}

.fa-external-link-alt {
    color: white;
}

.btn-link:hover {
    color: transparent !important;
    text-decoration: none !important;
}

.btn-link:focus,
.btn-linktn.focus {
    box-shadow: none !important;
}

#chat-panel .card-body {
    padding: 0px;
    padding-left: 0px;
    padding-right: 0px;

}

.accordion>.card {
    // padding-bottom: 4px;
    margin-bottom: 15px;

}

.tab-header {
    float: right;
}

.box-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag {
    /* this is the animation that fires for the drag */
    transition: transform 150ms ease;
}

.cdk-drag-placeholder {
    /* setting opacity to zero makes a blank space between list items during drag preview */
    opacity: 1;
    background-color: #0A529E;
    height: 20px;
}

.cdk-drag-preview {
    background-color: #0A529E;
    box-sizing: border-box;
    border-radius: 4px;
    //box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    opacity: 1;
    color: white !important;
}

.card-header {
    padding: 0px 1.25rem !important;
}

.desktop-table {
    th {
        color: #0A529E !important;
    }
}